<template>
    <v-app>
        <v-container>
           <form>
                <v-text-field
                    v-model="formData.email"
                    label="E-mail"
                    type="email"
                    required
                >
                </v-text-field>
                <v-text-field
                    v-model="formData.password"
                    :label="$t('password')"
                    type="password"
                    required
                >
                </v-text-field>
                <v-btn
                    class="mr-4 red darken-1"
                    @click="login"
                    depressed
                    dark
                >
                    {{ $t('login') }}  
                </v-btn>
            </form>            
        </v-container>
    </v-app>
</template>

<script>
export default {
    data(){
        return{
            formData:{
                email: '',
                password: ''
            }
        }
    }, 
    methods:{
        login(){
            this.$store.dispatch('login', this.formData).then(() => {
                this.$router.push('/');
            });
        },
    }
}
</script>
<style scoped>
form{
    max-width: 700px;
    padding: 50px;
}
</style>