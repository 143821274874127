import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login.vue'
import Main from '../components/main.vue'
import store from '../store/index.js'
import List from '../components/list.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    beforeEnter:(to, from, next) => {
      if(store.state.token){
        next()
      }else{
        router.push('/login').catch(()=>{});
      }
    }
    
  },
  {
    path: '/list',
    name: 'List',
    component: List,
    beforeEnter:(to, from, next) => {
      if(store.state.token){
        next()
      }else{
        router.push('/login').catch(()=>{});
      }
    }
    
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter:(to, from, next) => {
      if(!store.state.token){
        next()
      }else{
        router.push('/');
      }
    }
      
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
