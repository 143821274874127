<template>
    <v-app>
        <v-container class="main">
             <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="time"
                    :label="$t('chooseTime')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="time"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="time = '', $refs.menu.save(time)"
                >
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(time)"
                >
                    {{ $t('ok') }}
                </v-btn>
                </v-date-picker>
            </v-menu>
            <v-text-field
                label="Main input"
                v-model="name"
            >
            </v-text-field>
            <v-input
                v-model="takeName"
                no-title
                scrollable
                >
            </v-input>
            <v-simple-table dense>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        {{ $t('name') }}
                    </th>
                    <th class="text-left">
                        {{ $t('position') }}
                    </th>
                   
                    <th class="text-left">
                        {{ $t('timeOfLastEnter') }}
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(person, index) in data" :key="index">
                        <td @click="takeName(person.name)">{{ person.name }}</td>
                        <td>{{ person.info.Notes }}</td>
                        <td>{{ person.info.CreateTime }}</td>
                        
                    </tr> 
                </tbody>
                </template>
            </v-simple-table>
            <div v-if="clicked = false">
                <Pagination :total="total" :item="per_page" @page-changed="getPage" />
            </div>
            <div v-if="clicked = true">
                <Pagination :total="total" :item="per_page" @page-changed="getSearch" />
            </div>
            </v-container>
    </v-app>
</template>
<script>
import axios from 'axios';
import Header from '../components/Header'
import Footer from '../components/Footer'
import Pagination from './pagination.vue';
export default {
    components:{
        Header,
        Footer,
        Pagination
    },
    data(){
        return{
            data: {},
            page: 1,
            total: 0,
            per_page: 0,
            time: '',
            menu: false,
            name: '',
            clicked: false
        }
    },
    mounted(){
        this.getPage(this.page)
         
    },
    methods:{
        
        takeName($event){
            this.name = $event
            return this.name
        },
        getPage(pages){
            axios.get(`http://add.pass.mediana.kz/api/v1/getBeward?page=${pages}&token=`+localStorage.getItem("token")
            )
            .then(response => { 
                if (response.status === 200)     
                    this.total = response.data.total;
                    this.data = response.data.data;
                    this.per_page = response.data.per_page;
                    for(let i = 0; i < this.data.length; i++) {
                        this.data[i]['info'] = JSON.parse(this.data[i]['user_info']);
                    }
                }
            )
            .catch(error => console.log(error))
        },
        getSearch(pages){
            var self = this;
            axios.get(`http://add.pass.mediana.kz/api/v1/getBeward?page=${pages}&token=`+localStorage.getItem("token") + `&` + `name=`+self.name  + `&` + `date=`+self.time 
            )
            .then(response => { 
                if (response.status === 200)
                    this.total = response.data.total;
                    this.per_page = response.data.per_page;
                    this.data = response.data.data;
                    for(let i = 0; i < this.data.length; i++) {
                        this.data[i]['info'] = JSON.parse(this.data[i]['user_info']);
                    }
                    console.log(this.total)
                    console.log(this.per_page)

            }
            )
            .catch(error => console.log(error))
        },
        
    },
    created(){
        
    }
    
}
</script>
<style scoped>
.main{
    padding-top: 50px;
}
.v-input{
        max-width: 30% !important;
}
</style>