import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'ru',
  fallbackLocale: 'ru',
  messages: {
    ru:{
      logout: 'Выйти',
      todayList: 'Сегодня',
      allList: 'Полный список входящих',
      login: 'Войти', 
      cancel: 'Отменить',
      ok: 'OK', 
      name: 'Имя',
      position: 'Должность',
      firstEnterTime: 'Время входа',
      timeOfLate: 'Опоздания и приходы раньше',
      dateOfBirth: "Дата рождения",
      timeOfLastEnter: "Время последнего входа",
      chooseTime: "Выберите время",
      password: "Пароль",
      today: 'Сегодняшний список',
      timeOfLeave: "Время последнего выхода",
      na: "на ",
      before: " мин раньше",
      timeHour: " ч и ",
      late: "с опозданием на ",
      lateMin: " мин",
      onTime: 'вовремя'
    },
    kk:{
      logout: 'Шығу',
      todayList: 'Бүгін',
      allList: 'Кірушілердің толық тізізмі',
      login: 'Кіру', 
      cancel: 'Бас тарту',
      ok: 'OK', 
      name: 'Аты-жөні',
      position: 'Лауазымы',
      firstEnterTime: 'Кіру уақыты',
      timeOfLate: 'Ерте және кеш келу уақыттары',
      dateOfBirth: "Туған күні",
      timeOfLastEnter: "Соңғы кірген уақыты",
      chooseTime: "Уақыт таңдаңыз",
      password: "Құпия сөз",
      today: 'Бүгінгі тізім',
      timeOfLeave: "Соңғы шығу уақыты",
      na: '',
      before: " минутқа ерте",
      timeHour: " сағат және ",
      late: " ",
      lateMin: " минутқа кешікті",
      onTime: 'уақытында'
    }
  }
  // locales.keys().forEach(key => {
  //   const matched = key.match(/([A-Za-z0-9-_]+)\./i)
  //   if (matched && matched.length > 1) {
  //     const locale = matched[1]
  //     messages[locale] = locales(key)
  //   }
  // })

})

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })
