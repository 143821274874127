import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token"),
    lang: localStorage.getItem("lang") || 'ru'
  },
  getters:{
    isAuth(state){
      if(state.token){
        return true
      }else{
        return false
      }
    },
    getLanguage(state){
      return state.lang
    }
  },
  mutations: {
    auth(state, response){
      state.token = response.access_token;
    },
    logout(state){
      state.email = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("email");
    },
    setLang(state, lang) {
			localStorage.setItem('lang', lang);
			state.lang = lang;
		},
  },
  actions: {
    async login({commit}, payload){
      await axios.post('https://add.pass.mediana.kz/api/v1/login', {
        ...payload,
      })
      .then(response =>{
        commit("auth", response.data);
        // console.log(store.getters);
        // console.log(response.data);
        localStorage.setItem("token", response.data.access_token);
      })
      .catch(error => alert(error));
    }
  },
  modules: {
  }
})
